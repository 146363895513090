<template>
    <div >
        <div class="pageheader pd-t-25 pd-b-15">
            <div class="pd-t-5 pd-b-5">
                <h1 class="pd-0 mg-0 tx-20 text-overflow">Kode Akses</h1>
            </div>
            <div class="breadcrumb pd-0 mg-0">
                <a class="breadcrumb-item" href="#"><i class="fa fa-users mg-r-5"></i>Data Master</a>
                <span class="breadcrumb-item active"> Kode Akses</span>
            </div>
        </div> 
        <br><br><br><br>
        <!-- MAIN CONTENT ================================================== -->
        <div class="row justify-content-center">
            <div class="card shadow-none pd-20 mx-auto wd-300 text-center bd-1 align-self-center">
            <div class="text-center">
               <img src="https://scola-uploader.sgp1.digitaloceanspaces.com/upload/431f98add25ded6563ab086d/logo_itb_1024.png" class="rounded-circle img-fluid wd-60 mg-y-20" alt="">			   
            </div>
            <div class="clearfix">
               <form @submit.prevent="submitForm" enctype="multipart/form-data">
                    <div class="alert alert-danger" role="alert">
                        Kode Akses digunakan oleh pengguna agar dapat melihat progress kemajuan Mahasiswa
                    </div>
                    <div class="form-group input-group">
                        <div class="input-group-prepend">
                            <div class="input-group-text"><i class="fa fa-lock"></i></div>
                        </div>
                        <input type="text" v-model="form.akses" class="form-control" required="">
                    </div>
                    <div class="input-group mg-y-20">
                        <button class="col-12 btn btn-warning">Update Kode</button>
                    </div>
               </form>
            </div>
         </div>
         
        </div>
        <!-- END MAIN CONTENT -->
        <br><br><br><br><br><br><br><br>
    </div>
</template>

<script>
    export default {
        metaInfo: {
            title: 'FTSL',
            titleTemplate: '%s - Kode Akses'
        },
        data() {
            return {
                baseUrl: this.$apiconfig,
                kodeAkses: {},
                form: {
                    akses: '',
                },
            }
        },
        created() {
            this.getData();
        },
        components: {
        },
        methods: {
            getData() {
                this.$http.get(this.baseUrl + 'admin/master/akses', {
                    params: {
                        keywords: this.keywords,
                    }
                })
                    .then((response) => {
                        this.form.akses = response.data.data.akses;
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                    });
            },
            submitForm() {
                this.$http.post(this.baseUrl + 'admin/master/akses/save', this.form)
                    .then(() => {
                        this.$swal({
                            icon: 'success',
                            title: "success",
                            text: 'Kode Akses berhasil diperbaharui',
                            showConfirmButton: false,
                            timer: 1500
                        })
                        this.getData()
                    })
                    .catch(error => {
                        console.log(error.response.data)
                    });
            },
        },
    };
</script>
<style>
    /* style sweetalert */
    .swal2-html-container {
        display: block;
        text-align: center;
    }
</style>